@import url('https://fonts.googleapis.com/css?family=Allura|Gruppo');


@font-face {
    font-family: 'Gruppo', cursive;
    src: url('Gruppo.eot'); /* IE9 Compat Modes */
    src: url('Gruppo.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('Gruppo.woff') format('woff'), /* Modern Browsers */
         url('Gruppo.ttf')  format('truetype'), /* Safari, Android, iOS */
         url('Gruppo.svg#svgFontName') format('svg'); /* Legacy iOS */
    }




$primary: #e7e7e7; /* MAIN COLOR */
$secondary: #c50000; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fff;


.carousel-indicators .active {
    background-color: #473972;
}


.flash {
  display: none;
}

i.fa {
  color: #fff;
  padding: 10px 0;
}

nav {
  z-index: 1000;
  color: #333;
}

h1 {
  font-family: 'Allura', cursive;
  color:#fff;
}


h2, h3{
  font-family: 'Allura', cursive;
  color:#332766;
}

p {
  font-family: 'Gruppo', Allura, cursive;
  font-weight: 400;
  font-size: 2.0rem;
  line-height: 1.5em;
  color: #000;
}

@media (max-width: 670px) and (orientation: landscape) {
  h1 {
    font-family: 'Allura', cursive;
    line-height: 1.5em;
    font-size: 2em;
  }
}

@media (max-width: 767px) {
  h2 {
    line-height: 1.5em;
    font-size: 2.4rem;
  }
}

/** NAV **/

.navbar {
  margin-bottom: 0px;
  background-color: #ccc;
  background-image: url(../img/header_bg.jpg);
  background-position: 0% 0%;
  background-size: cover;
  background-attachment: scroll;
  background-repeat: no-repeat;
  border-color: transparent;
  .navbar-nav {
    margin-top: 29px;
    padding-bottom: 13px;
    > li > a {
      text-align: center;
      /*height: 100px;*/
      font-size: 17px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      color: #332766;
      &:hover, &:focus {
        color: #d24893;

      }
    }
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .navbar-header {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .navbar .navbar-nav {
    margin-top: 0px;
  }
}

@media (max-width: 767px) {
  .navbar .navbar-nav > li > a {
    height: 20px;
    font-size: 14px;
    display: inline-block;
  }
}

.navbar-collapse {
  border: none;
}

.navbar-right {
  margin-top: 0px;
}

.navbar-toggle {
  margin: 36px 15px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #00daff;
  &:hover {
        color: #ddd;
  }
}

@media (max-width: 320px) {
  .navbar-toggle {
    margin-top: 36px;
  }
}

@media (max-width: 568px) and (orientation: landscape) {
  .navbar-toggle {
    margin-top: 36px;
  }
}

@media (max-device-width: 767px) and (orientation: landscape) {
  .navbar-toggle {
    margin: 36px 15px;
  }
}

/**  END NAV **/

/**  LOGO  **/

.logo {
  max-width: 400px;
  width: 100%;
}


@media (max-width: 375px) {
.logo {
  max-width: 200px;
  width: 100%;
  padding: 10px 0px 0px 20px;
  }
}

@media (max-width: 768px) {
.logo {
  max-width: 220px;
  width: 100%;
  padding: 25px 0px 0px 5px;
  }
}

@media (max-width: 1024px) {
.logo {
  max-width: 225px;
  width: 100%;
  padding: 25px 0px 0px 5px;
  }
}
@media (max-width: 667px) and (orientation: landscape) {
.logo {
  max-width: 300px;
  width: 100%;
  padding: 20px 0px 0px 20px;
  }
}


@media (max-width: 736px) and (orientation: landscape) {
.logo {
  max-width: 300px;
  width: 100%;
  padding: 20px 0px 0px 20px;
  }
}

/**  END LOGO  **/

/** LOGIN FORM **/

.modal-dialog {
  max-width: 250px;
  text-align: center;
  margin: 6em auto;
  .close {
    display: none;
  }
  .modal-content {
    color: #blk;
    h2 {
      text-align: center;
    }
  }
  input {
    border: none;
    border-bottom: 1px solid #b4b4b4;
    text-align: center;
  }
  button {
    background: transparent;
    color: #333;
    display: block;
    border: none;
    padding: 1em 2em;
    margin: 0 auto;
    &:hover {
      background: transparent;
      color: #333;
      box-shadow: none;
      text-shadow: 0px 0px 3px #333;
      border: none;
    }
  }
  input[type="submit"] {
    text-align: center;
    margin: 0 auto;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    background: #e7e7e7;
    color: #333;
    padding: 0.5em 2em;
    display: block;
    width: 75%;
    &:hover {
      background: white;
      color: #4d4d4d;
      box-shadow: 0px 0px 3px #4d4d4d;
    }
  }
}

.modal-header, .modal-footer {
  background: #e7e7e7;
  color: #333;
}

.modal-header {
  border-radius: 5px 5px 0 0;
}

.modal-footer {
  border-radius: 0 0 5px 5px;
}

input {
  &#username {
    margin-bottom: 20px;
    width: 100%;
    font-size: 16px;
  }
  &#password {
    width: 100%;
    font-size: 16px;
  }
}

@media (max-width: 1024) {
  input {
    &#username, &#password {
      font-size: 16px;
    }
  }
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

/** END LOGIN FORM **/
/** FOOTER **/

footer {  
  margin-bottom: 0px;
  background-color: #ccc;
  background-image: url(../img/footer_bg.jpg);
  background-position: 0% 100%;
  background-size: cover;
  background-attachment: scroll;
  background-repeat: no-repeat;
  border-color: transparent;
  padding: 30px 0px 20px !important;
  color: #332766 !important;
  a {
    color: #332766;
    &:hover {
      color: #e53996;
    }
  }
  p {
      font-size: 1.2em;
      font-weight: bold;
     color: #332766 !important;
  }
}

/** END FOOTER **/

.sticky-wrapper {
  height: auto !important;
}

.section-heading {
  font-size: 200%;
  font-weight: normal;
}

.text-center {
  text-align: center;
}

.text-space {
  line-height: 1.5em;
}

@media (max-width: 991px) {
  .text-space {
    line-height: 1.0em;
  }
}

.text-min-space {
  line-height: 1.5em;
  margin: 0 -10px 0 -10px;
}

.text-shadow {
  text-shadow:-1px 2px 1px rgb(51, 39, 102);
}

@media (max-width: 450px) {
  .text-shadow {
    font-size: 20px;
  }
}

.btn {
  background-color: rgb(0, 218, 255);
  border-color: white;
  font-size: 15px;
  padding: 1em 3em;
  color: #fafafa;
  &:hover {
    background-color: #ddd;
    color: #333;
  }
}

.marTop {
  margin-top: 2em;
}

.marBot {
  margin-bottom: 2em;
}

.blkTxt {
  color: #000;
}

/** SECTION A  **/

/*.content-section-a {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(64, 231, 255, 0.65) 100%), url(../img/banner.jpg);
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(64, 231, 255, 0.65) 100%), url(../img/banner.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  padding: 200px 0;
  background-position: center center;
  color: #000;
}*/

.section-a-txt {
  font-size: 18px;
  line-height: 1.5em;
}

.content-section-a {
  padding: 50px 0;
  background-color: #f8f8f8;
}

/** END SECTION A **/

/** SECTION B  **/

/*.content-section-b {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(64, 231, 255, 0.65) 100%), url(../img/banner.jpg);
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(64, 231, 255, 0.65) 100%), url(../img/banner.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  padding: 200px 0;
  background-position: center center;
  color: #000;
}*/

.content-section-b {

  .carousel-caption {
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
  }

  h1 {
    font-size: 60px;
    font-weight: 300;
    text-shadow: 2px 2px 4px #69189c;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 600px;
  }
    
  p {
    position: absolute;
    font-weight:bolder;
    width: 600px;  
    font-size: 25px;
    top: 15%;
    left: 0%;
	color:#332766;
    text-shadow: 0px 0px 4px #fff;
  }
}

/** SECTION B MEDIA QUERIES **/

@media (max-width: 1024px) {
  h1.tagline {
    font-size: 60px;
    font-weight: 300;
    top: 0%;
    left: -50%;
    position: absolute;
    width: 600px;
  }
  p.cap_loc {
    position: absolute;
    font-weight:bold;
    width: 500px;  
    font-size: 25px;
    top: 25%;
    left: -30%;
  }
}

@media (max-width: 768px) {
  h1.tagline {
    font-size: 50px;
    font-weight: 300;
    top: 0%;
    left: -65%;
    position: absolute;
    width: 500px;
  }

  p.cap_loc {
    position: absolute;
    font-weight: bold;
    width: 500px;  
    font-size: 25px;
    top: 30%;
    left: -60%;
  }
}

@media (max-width: 414px) {
  h1.tagline {
    font-size: 35px;
    font-weight: 300;
    top: 0%;
    left: -55%;
    position: absolute;
    width: 300px;
  }
}

@media (max-width: 375px) {
  h1.tagline {
    font-size: 30px;
    font-weight: 300;
    top: 0%;
    left: -50%;
    position: absolute;
    width: 260px;
  }
}

@media (max-width: 320px) {
    h1.tagline {
    font-size: 30px;
    font-weight: 300;
    top: 0%;
    left: -65%;
    position: absolute;
    width: 260px;
  }
}

/** END SECTION B **/
@media (max-width: 1366px) and (orientation: landscape) {
  h1.tagline.pos1 {
    font-size: 70px;
    font-weight: 300;
    top: 5%;
    left: 35%;
    position: absolute;
    width: 600px;
  }
    h1.tagline.pos2 {
    font-size: 70px;
    font-weight: 300;
    top: -5%;
    left: -105%;
    position: absolute;
    width: 600px;
  }
  p.cap_loc.pos1 {
    position: absolute;
    font-size: 40px;
    font-weight: bold;
    top: 25%;
    left: 8%;
    width: 820px;
  }
   p.cap_loc.pos2 {
    position: absolute;
    font-size: 40px;
    font-weight: bold;
     top: 14%;
    left: -131%;
    width: 820px;
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  h1.tagline.pos1 {
    font-size: 60px;
    font-weight: 300;
    text-shadow: 2px 2px 4px #69189c;
    position: absolute;
    top: 0%;
    left: 24%;
    width: 600px;
  }
    h1.tagline.pos2 {
    font-size: 60px;
    font-weight: 300;
    text-shadow: 2px 2px 4px #69189c;
    position: absolute;
    top: 0%;
    left: -115%;
    width: 600px;
  }
  p.cap_loc.pos1 {
    position: absolute;
    font-size: 26px;
    font-weight: bold;
    top: 25%;
    left: 25%;
    width: 600px;
  }
   p.cap_loc.pos2 {
    position: absolute;
    font-size: 26px;
    font-weight: bold;
    top: 25%;
    left: -112%;
    width: 600px;
  }
}

@media (max-width: 736px) and (orientation: landscape) {
  h1.tagline.pos1 {
    font-size: 45px;
    font-weight: 300;
    top: -32%;
    left: 10%;
    position: absolute;
    width: 500px;
  }
    h1.tagline.pos2 {
    font-size: 45px;
    font-weight: 300;
    top: -30%;
    left: -110%;
    position: absolute;
    width: 500px;
  }
}

@media (max-width: 667px) and (orientation: landscape) {
  h1.tagline.pos1 {
    font-size: 35px;
    font-weight: 300;
    top: -35%;
    left: 45%;
    position: absolute;
    width: 300px;
  }
    h1.tagline.pos2 {
    font-size: 35px;
    font-weight: 300;
    top: -40%;
    left: -75%;
    position: absolute;
    width: 300px;
  }
}

@media (max-width: 568px) and (orientation: landscape) {
  h1.tagline {
    font-size: 30px;
    font-weight: 300;
    top: -40%;
    left: -10%;
    position: absolute;
    width: 260px;
  }
}

/** END SECTION B **/

/** SECTION C  **/

.content-section-c {
  /*background: url(../img/city_street_cabs.jpg);*/
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%, #40e7ff 100%), url(../img/signup_bg.jpg);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%, #40e7ff 100%), url(../img/signup_bg.jpg);
  background-position: center top;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding: 50px 20px;
  color: #fff;
  
  h2.tagline {
      color: white;
      font-size: 50px;
      font-weight: 400;
    }
    
  p.cap_loc2 {
    color: white;
    font-size: 20px;
    font-weight: 300;
    // text-shadow: 2px 2px 4px rgb(105, 24, 156);
  }

}

/** SECTION C MEDIA QUERIES **/

@media (max-width: 1024px) {
  .content-section-c {
    background-attachment: scroll;
  }
}

@media (max-width: 767px) {
  .content-section-c {
    background-attachment: scroll;
  }
}
@media (max-width: 568px) and (orientation: landscape){
  .content-section-c {    
    background-position: center center;
    background-attachment: scroll;

 h2.tagline {
      color: white;
      font-size: 50px;
      font-weight: 400;
    }
    
  p.cap_loc2 {
    color: white;
    font-size: 15px;
    font-weight: 300;
    // text-shadow: 2px 2px 4px rgb(105, 24, 156);
    }
  }
}


@media (max-width: 320px) {
  .content-section-c {    
    background-position: center center;
    background-attachment: scroll;

 h2.tagline {
      color: white;
      font-size: 50px;
      font-weight: 400;
    }
    
  p.cap_loc2 {
    color: white;
    font-size: 15px;
    font-weight: 300;
    // text-shadow: 2px 2px 4px rgb(105, 24, 156);
    }
  }
}
/** END SECTION C **/

/** SECTION D **/

.content-section-d {
  padding: 50px 0px;
  background-color: #920000;
  color: #fff;
}

/*
.img1 {
 //background: url(../img/roller_girl.jpg);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 60%, rgb(64, 231, 255) 100%), url(../img/roller_girl.jpg) no-repeat;
  height: 70%;
  background-repeat: no-repeat;
  //background-attachment: fixed;
  background-size: cover;
  padding: 300px 20px;
  //background-position: 11% 20%;
  color: #fff;
  background-color: #f8f8f8;

    @media(max-width: 1024px) {
      // background-position: 0em 0em;
      background-position: center center;
    }
}
*/
/*
.img2 {

  //background: url(../img/skater_against_wall.jpg);
   background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 60%, rgb(64, 231, 255) 100%), url(../img/skater_against_wall.jpg) no-repeat;
  height: 70%;
  background-repeat: no-repeat;
  //background-attachment: fixed;
  background-size: cover;
  padding: 300px 20px;
  //background-position: 11% 20%;
  color: #fff;
  background-color: #f8f8f8;

      @media(max-width: 1024px) {
      // background-position: 0em 0em;
      background-position: center center;
      
    }
}
*/
/*
.img3 {
  //background: url(../img/roller_girl_fence.jpg);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 60%, rgb(64, 231, 255) 100%), url(../img/roller_girl_fence.jpg) no-repeat;
  height: 70%;
  background-repeat: no-repeat;
  //background-attachment: fixed;
  background-size: cover;
  padding: 300px 20px;
  //background-position: 11% 20%;
  color: #fff;
  background-color: #f8f8f8;
      @media(max-width: 1024px) {
      //background-position: 0em 0em;
      background-position: 25% 20%;
      
    }
}
*/
/*
.img4 {
  
  // background: url(../img/skater_jump_trick.jpg);
  // background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 60%, rgb(64, 231, 255) 100%), url(../img/skater_jump_trick.jpg) no-repeat;
  // height: 70%;
  // background-repeat: no-repeat;
  // background-attachment: fixed;

  background-size: cover;
  padding: 300px 20px;
  //background-position: 11% 20%;
  color: #fff;
  background-color: #f8f8f8;
        @media(max-width: 1024px) {
      // background-position: 0em 0em;
      background-position: 25% 20%;
      
    }
}
*/

/** END SECTION D **/

/** SECTION E **/

.content-section-e {
  // background: url(../img/img6.jpg);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%, #40e7ff 100%), url(../img/img6.jpg);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%, #40e7ff 100%), url(../img/img6.jpg);
  background-position: 0px 0px;
  background-size: cover;
  background-attachment: scroll;
  background-repeat: no-repeat;
  padding: 200px 0px;
  color: #fff;

  h2.tagline {
      color: white;
      font-size: 50px;
      font-weight: 400;
  }
    
  p.cap_loc2 {
    color: white;
    font-size: 20px;
    font-weight: 300;
    // text-shadow: 2px 2px 4px rgb(105, 24, 156);
  }

}

/** SECTION E MEDIA QUERIES **/

@media (max-device-width: 767px) and (orientation: landscape) {
  .content-section-e {
    background-position: center center;
  }
}

@media (max-width: 670px) {
  .content-section-e {
    background-position: center center;
  }
}

@media (max-width: 660px) and (orientation: portrait) {
  .content-section-e {
    background-position: center center;
  }
}

@media (max-width: 670px) and (orientation: landscape) {
  .content-section-e {
    
    background-position: center center;
  }
}

@media (max-width: 375px) {
  .content-section-e {
     background-position: center left;
    
  }
}

@media (max-width: 568px) and (orientation: landscape){
  .content-section-e {    
    background-position: center center;
    background-attachment: scroll;

 h2.tagline {
      color: white;
      font-size: 50px;
      font-weight: 400;
    }
    
  p.cap_loc2 {
    color: white;
    font-size: 15px;
    font-weight: 300;
    // text-shadow: 2px 2px 4px rgb(105, 24, 156);
    }
  }
}


@media (max-width: 320px) {
  .content-section-e {    
    background-position: center center;
    background-attachment: scroll;

 h2.tagline {
      color: white;
      font-size: 50px;
      font-weight: 400;
    }
    
  p.cap_loc2 {
    color: white;
    font-size: 15px;
    font-weight: 300;
    // text-shadow: 2px 2px 4px rgb(105, 24, 156);
    }
  }
}

/** END SECTION E **/